<template>
<div>
    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Gestión de revisión por la dirección</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(registrarRevision)">
                            <b-row>
                                <b-col md="4">
                                    <validation-provider name="N° de revisión" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="N° de revisión:" class="mb-2">
                                            <b-form-input type="number" :state="getValidationState(validationContext)" v-model.lazy="datosRevision.numeroRevision"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="4">
                                    <validation-provider name="hora inicio" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Hora inicio:" class="mb-2">
                                            <b-form-input type="time" :state="getValidationState(validationContext)" v-model.lazy="datosRevision.horaInicio"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>

                                <b-col md="4">
                                    <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Fecha:" class="mb-2">
                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model.lazy="datosRevision.fecha"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="4">
                                    <validation-provider name="hora fin" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Hora fin:" class="mb-2">
                                            <b-form-input type="time" :state="getValidationState(validationContext)" v-model.lazy="datosRevision.horaFin"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>

                                <b-col md="4">
                                    <validation-provider name="objetivo" :rules="{required: true,max:499}" v-slot="validationContext">
                                        <b-form-group label="Objetivo:" class="mb-2">
                                            <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el objetivo" v-model.lazy="datosRevision.objetivo"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="4">
                                    <validation-provider name="alcance" :rules="{required: true,max:499}" v-slot="validationContext">
                                        <b-form-group label="Alcance:" class="mb-2">
                                            <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el alcance" v-model.lazy="datosRevision.alcance"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="12" class="mt-3">
                                    <span class="h5 text-muted">Asistentes a la revisión</span>
                                    <CButton @click="agregarFilaAsistente()" class="float-right" size="sm" color="dark">
                                        <i class="fas fa-plus fa-sm"></i> Agregar
                                    </CButton>
                                    <hr>
                                </b-col>
                                <b-col md="12">
                                    <b-table :items="datosRevision.listaAsistentes.filter(x => x.estado=='2')" :fields="campoAsistentes" class="table-custom" mediun responsive outlined fixed hover>
                                        <template v-slot:cell(nombre)="row">
                                            <b-form-input type="text" v-model.lazy="row.item.nombre" placeholder="Ingrese nombre"></b-form-input>
                                        </template>
                                        <template v-slot:cell(puesto)="row">
                                            <b-form-input type="text" v-model.lazy="row.item.puesto" placeholder="Ingrese puesto"></b-form-input>
                                        </template>
                                        <template v-slot:cell(firma)="row">
                                            <!-- <VueSignaturePad v-model.lazy="row.item.firma" :id="'signature-'+row.index" width="50%" height="150px" class="float-center" :ref="'signaturePad-'+row.index" /> -->
                                            <b-form-group>
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="row.item.urlFirma != ''">
                                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlFirma)" v-c-tooltip="'Descargar'">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file ref="file" v-model.lazy="row.item.firma" v-on:change="handleFileUpload($event, row)" accept=".png, .jpg, .jpge" :placeholder="row.item.nombreFirma ? row.item.nombreFirma : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreFirma ? row.item.nombreFirma : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>

                                                </b-input-group>
                                            </b-form-group>
                                            <CImg alt="firma" :src="row.item.preview ? row.item.preview : row.item.urlFirma" class="img-fluid" width="200" height="80"></CImg>
                                        </template>
                                        <template #cell(acciones)="param">
                                            <b-button @click="quitarFilaAsistente(param)" v-if="datosRevision.listaAsistentes.filter(x => x.estado=='2').length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                </b-col>

                                <b-col class="h5 text-muted" md="12">
                                    Los puntos tratados y las conclusiones tomadas se muestras a continuación:
                                </b-col>
                                <b-col md="12" class="mt-3">
                                    <b-table-simple class="table-custom" mediun responsive outlined fixed hover>
                                        <b-thead>
                                            <b-th>
                                                Elementos de entrada y conclusiones/observaciones
                                            </b-th>
                                            <b-th>
                                                Anexos/Registros
                                            </b-th>
                                            <b-th>
                                                Mejoras propuestas
                                            </b-th>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr>
                                                <b-td width="40%">
                                                    1 - Revisión de la política de calidad
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[0].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td width="30%">
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[0].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td width="30%">
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[0].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    2 - Análisis del procedimiento de Requisitos Legales
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[1].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[1].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[1].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    3 - Evaluación y análisis del proceso de prestación del servicio
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[2].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[2].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[2].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    4 - Personal de la empresa – evaluación de los procesos de selección de personal
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[3].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[3].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[3].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    5 - Resultados del análisis de infraestructuras y equipos
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[4].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[4].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[4].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    6 - Resultados de la evaluación de proveedores
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[5].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[5].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[5].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    7 - Establecimiento de nuevos objetivos para los siguientes ejercicios
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[6].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[6].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[6].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    8 - Seguimiento y medición de los procesos (Indicadores)
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[7].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[7].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[7].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    9 - Retroalimentación del cliente – satisfacción
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[8].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[8].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[8].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    10 - Retroalimentación del cliente: Reclamaciones, quejas, sugerencias y oportunidades de mejora
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[9].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[9].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[9].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    11 - No conformidades detectadas por el sistema y acciones implantadas para su resolución
                                                    <br> No conformidades generales:
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[10].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                    No conformidades detectadas en la auditoría interna:
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[11].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[10].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[10].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    12 - Análisis de los riesgos identificados en la organización
                                                    <br> Los riesgos identificados en la organización son los siguientes:
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[12].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[12].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[12].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    13 - Estado de la implantación del sistema y grado de cumplimiento de los requisitos impuestos
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[13].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[13].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[13].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    14 - Acciones de seguimiento de otras revisiones llevadas a cabo por la Dirección
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[14].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[14].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[14].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    15 - Planificación de auditorías
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[15].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[15].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[15].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>
                                                    16 - Resultados de auditorías internas
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[16].entradaConclusiones" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[16].anexosRegistros" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                                <b-td>
                                                    <b-form-textarea placeholder="Escriba aquí" v-model.lazy="datosRevision.listaPuntosTratados[16].mejorasPropuestas" rows="3" max-rows="6"></b-form-textarea>
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>

                                        <!-- <template slot="elementosEntrada">
                                            <b-form-input type="text"></b-form-input>
                                        </template> -->
                                    </b-table-simple>
                                </b-col>

                                <b-col md="12">
                                    <validation-provider name="Oportunidades de mejora y resumen de conclusiones de este informe de revisión del sistema" :rules="{ }" v-slot="validationContext">
                                        <b-form-group label="Oportunidades de mejora y resumen de conclusiones de este informe de revisión del sistema" class="mb-2">
                                            <b-form-textarea v-model.lazy="datosRevision.oportunidadesMejora" rows="4" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las oportunidades de mejora"></b-form-textarea>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col class="my-2 text-center" md="12">
                                    <b-button :to="{name: 'Revisión por la dirección'}" class=" mr-2" variant="dark" @click="modalAnadirEquipo = false">
                                        <i class="fas fa-arrow-left"></i> Volver
                                    </b-button>
                                    <b-button :disabled="disabled" v-if="$route.params.id  && checkPermissions('010-MEJ-REV','u')==1" variant="success" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                    <b-button :disabled="disabled" v-if="!$route.params.id && checkPermissions('010-MEJ-REV','c')==1" variant="success" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>

                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            options: [
                'item 1',
                'item 2',
                'item 3',
                'item 4',
                'item 5'
            ],
            cabeceraPuntos: [{
                    key: "entraConclusiones",
                    label: "Elementos de entrada y conclusiones/observaciones",
                    class: "text-center",
                }, {
                    key: "anexosRegistros",
                    label: "Anexos/registros",
                    class: "text-center",
                }, {
                    key: "mejorasPropuestas",
                    label: "Mejoras propuestas",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    class: "text-center"
                }
            ],
            campoAsistentes: [{
                key: "nombre",
                label: "Nombre",
                class: "text-center",
            }, {
                key: "puesto",
                label: "Puesto",
                class: "text-center",
            }, {
                key: "firma",
                label: "Firma",
                class: "text-center",
            }, {
                key: "acciones",
                label: "",
                class: "text-center",
            }],
            optionsPad: {
                penColor: "#c0f"
            },
            datosRevision: {
                idRevisionDireccion: '',
                numeroRevision: '',
                horaInicio: moment().format('HH:mm'),
                fecha: moment().format('YYYY-MM-DD'),
                horaFin: moment().format('HH:mm'),
                objetivo: '',
                alcance: '',
                oportunidadesMejora: '',
                listaAsistentes: [{
                    idRevisionAsistente: '',
                    idRevisionDireccion: '',
                    nombre: '',
                    puesto: '',
                    firma: null,
                    urlFirma: '',
                    nombreFirma: '',
                    preview: null,
                    estado: 2,
                }],
                listaPuntosTratados: [{
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 1,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 2,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 3,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 4,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 5,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 6,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 7,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 8,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 9,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 10,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 11,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 11.2,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 12,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 13,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 14,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 15,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                    {
                        idRevisionPuntoTratado: '',
                        idRevisionDireccion: '',
                        numeroIdentificador: 16,
                        entradaConclusiones: '',
                        anexosRegistros: '',
                        mejorasPropuestas: ''
                    },
                ]

            },
            datosSession: {
                idCliente: ''
            },
            disabled: false,

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        descargarDocumento(url) {
            window.open(url)
        },
        handleFileUpload(e, row) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                const file = e.target.files[0];
                this.datosRevision.listaAsistentes[row.index].preview = URL.createObjectURL(file);

            }
        },
        agregarFilaAsistente() {
            this.datosRevision.listaAsistentes.push({
                idRevisionAsistente: '',
                idRevisionDireccion: '',
                nombre: '',
                puesto: '',
                firma: null,
                urlFirma: '',
                nombreFirma: '',
                preview: null,
                estado: 2,
            })
        },
        listarRevisionesAsistentes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-revisiones-asistentes", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        idRevisionDireccion: me.$route.params.id,
                    }
                })
                .then(function (response) {
                    me.datosRevision.idRevisionDireccion = response.data[0].idRevisionDireccion;
                    me.datosRevision.numeroRevision = response.data[0].numeroRevision;
                    me.datosRevision.horaInicio = response.data[0].horaInicio;
                    me.datosRevision.fecha = response.data[0].fecha;
                    me.datosRevision.horaFin = response.data[0].horaFin;
                    me.datosRevision.objetivo = response.data[0].objetivo;
                    me.datosRevision.alcance = response.data[0].alcance;
                    me.datosRevision.oportunidadesMejora = response.data[0].oportunidadesMejora;

                    me.datosRevision.listaAsistentes.splice(0, 1);
                    for (const i in response.data) {
                        me.datosRevision.listaAsistentes.push({
                            idRevisionAsistente: response.data[i].idRevisionAsistente,
                            idRevisionDireccion: response.data[i].idRevisionDireccion,
                            nombre: response.data[i].nombre,
                            puesto: response.data[i].puesto,
                            firma: response.data[i].firma,
                            urlFirma: response.data[i].urlFirma,
                            nombreFirma: response.data[i].nombreFirma,
                            estado: response.data[i].estadoAsistente,
                        })
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarRevisionesPuntosTratados() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-revisiones-puntos-tratados", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        idRevisionDireccion: me.$route.params.id,
                    }
                })
                .then(function (response) {
                    me.datosRevision.listaPuntosTratados = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarNumeroRevision() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-numero-revision", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    }
                })
                .then(function (response) {
                    let num = 0;
                    if (!response.data.length == 0) {
                        num = response.data[0].numeroRevision;
                    }
                    me.datosRevision.numeroRevision = num + 1;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarRevision() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            let idRevAsis = 0;

            for (const i in me.datosRevision.listaAsistentes) {
                if (me.datosRevision.listaAsistentes[i].idRevisionAsistente == '' && me.datosRevision.listaAsistentes[i].firma != null) {
                    idRevAsis = idRevAsis - 1;
                    formData.append("file&&" + idRevAsis, me.datosRevision.listaAsistentes[i].firma);
                } else {
                    formData.append("file&&" + me.datosRevision.listaAsistentes[i].idRevisionAsistente, me.datosRevision.listaAsistentes[i].firma);
                }
            }

            formData.append("datosRevision", JSON.stringify(me.datosRevision));
            //formData.append("datosRevision.listaAsistentes", JSON.stringify(me.datosRevision.listaAsistentes));
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("folder", 'mejora-continua/revision-por-la-direccion');

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-revision-direccion",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.disabled = false;
                    me.$router.push({
                        name: 'Revisión por la dirección',
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        quitarFilaAsistente(param) {
            let me = this;
            if (this.$route.params.id) {
                let listaAsistentes = me.datosRevision.listaAsistentes.filter(x => x.estado == 2);
                if (listaAsistentes[param.index].idRevisionAsistente) {
                    listaAsistentes[param.index].estado = 1;
                } else if (!listaAsistentes[param.index].idRevisionAsistente) {
                    listaAsistentes[param.index].estado = 0;
                    for (let e in me.datosRevision.listaAsistentes) {
                        if (me.datosRevision.listaAsistentes[e].estado == 0) {
                            me.datosRevision.listaAsistentes.splice(e, 1);
                        }
                    }
                }
            } else {
                me.datosRevision.listaAsistentes.splice(param.index, 1);
            }
        },
        resetModalAnadirEquipo() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },
        resetModalVerDetalleEquipo() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });

        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            if (this.$route.params.id != null) {
                this.listarRevisionesAsistentes();
                this.listarRevisionesPuntosTratados();
            } else {
                this.consultarNumeroRevision();
            }
        }

    }

}
</script>
